<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CAlert
              :color="getcolor"
              closeButton
              :show.sync="dismissCountDown"
              fade
          >
            {{ msg }}
          </CAlert>
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="onSubmit">
                  <h1>Reset Password</h1>
                  <p class="text-muted">Reset Your Password</p>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-user"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.email"
                          type="email"
                          class="form-control"
                          autocomplete="email"
                          v-model="forgot.email"
                          placeholder="Email"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="$v.forgot.email.$error && !$v.forgot.email.required"
                    >Email is required</small
                    >
                    <small
                        class="error"
                        v-if="$v.forgot.email.$error && !$v.forgot.email.email"
                    >Email is not valid</small
                    >
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-user"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.password"
                          type="password"
                          class="form-control"
                          autocomplete="password"
                          v-model="forgot.password"
                          placeholder="Password"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="
                        $v.forgot.password.$error &&
                        !$v.forgot.password.required
                      "
                    >Password is required</small
                    >
                    <small
                        class="error"
                        v-if="
                        $v.forgot.password.$error &&
                        !$v.forgot.password.minLength
                      "
                    >Password minimum length is 8 character</small
                    >
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-user"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.confirmpassword"
                          type="password"
                          class="form-control"
                          autocomplete="password"
                          v-model="forgot.confirmpassword"
                          placeholder="Confirm Password"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="
                        $v.forgot.confirmpassword.$error &&
                        !$v.forgot.confirmpassword.sameAsPassword
                      "
                    >Confirm Password is same as password</small
                    >
                  </div>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit"
                      >Update Password
                      </CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import Vue from "vue";
import {General} from "/src/store/url.js";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import {required, minLength, email, sameAs} from "vuelidate/lib/validators";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {Maxlength} from "/src/store/maxlength";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};

Vue.use(VueNotifications, options);
// Register it globally

export default {
  name: "ResetPassword",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      dismissCountDown: 0,
      getcolor: "danger",
      msg: "",
      forgot: {
        email: "",
        confirmpassword: "",
        password: "",
      },
      maxlength: {
        email: Maxlength.forgot.email,
        confirmpassword: Maxlength.forgot.confirmpassword,
        password: Maxlength.forgot.password,
      },
      module: General,
    };
  },
  validations: {
    forgot: {
      email: {
        required,
        email,
      },
      confirmpassword: {
        sameAsPassword: sameAs("password"),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    this.dismissCountDown = 0;
  },
  methods: {
    onSubmit() {
      this.$v.forgot.$touch();
      if (this.$v.forgot.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        let postData = {
          token: this.$route.params.token,
          email: self.forgot.email,
          password_confirmation: self.forgot.confirmpassword,
          password: self.forgot.password,
        };
        store.commit("showLoader", true); // Loader Off
        axios
            .post(this.resetUrlApi(this.module), postData)
            .then(function (response) {
              if (response.status === 200) {
                self.msg = response.data.response.message;
                self.dismissCountDown = 10;

                store.commit("showLoader", false); // Loader Off
                if (
                    response.data.response.message ===
                    "Your password has been reset!"
                ) {
                  self.$router.push({path: "/signin"});
                }
                store.commit("showLoader", false); // Loader Off
                self.getcolor = "success";
                self.forgot.email = "";
                self.forgot.confirmpassword = "";
                self.forgot.password = "";
              }
            });
      }
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
};
</script>
